import React from "react";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";

const PersonalPortfolio = () => {
  return (
    <div id="portfolio">
      <div className="rwt-portfolio-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Business Projects"
                title="Our Projects"
              />
            </div>
          </div>
          <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
        </div>
      </div>
    </div>
  );
};
export default PersonalPortfolio;
