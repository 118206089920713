import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import React from "react";
import "./accordian.css";
// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;

function AccordionOne({ getItems }) {
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #ffff",
    fontSize: 17,

    color: token.colorTextSecondary,
  };
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: "transparent",
        color: "#ffff",
      }}
      items={getItems(panelStyle)}
      accordion
    />
  );
}

export default AccordionOne;
