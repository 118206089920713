import React from "react";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";

const BannerData = [
  {
    image: "/images/bg/bg-image-1.jpg",
    title: "Mobile and Web Development",
    description:
      "Welcome to our one-stop destination for cutting-edge mobile and web development solutions. UI/UX design to seamless functionality. ",
  },
  {
    image: "/images/bg/bg-image-14.jpg",
    title: "Result-Driven SEO Strategies",
    description:
      "Our dedicated SEO experts are passionate about driving organic traffic to your website, enhancing your rankings, and maximizing your online visibility. ",
  },
  {
    image: "/images/bg/bg-image-15.jpg",
    title: "AI Innovation Hub",
    description:
      "Welcome to our AI innovation hub, where we combine cutting-edge technology with human ingenuity to create intelligent solutions for businesses and industries. ",
  },
];

const DigitalAgency = () => {
  return (
    <>
      <main className="page-wrapper">
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1
                          className="title"
                          style={{ fontSize: "50px" }}
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        {/* <div className="button-group mt--30">
                          <a
                            className="btn-default"
                            target="_blank"
                            href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                          >
                            PURCHASE NOW
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </main>
    </>
  );
};
export default DigitalAgency;
