import React, { useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import BlogGridViewPage from "../components/blog/BlogGridViewPage";

const Blogs = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <BlogGridViewPage />
      <FooterOne requestMeeting={() => setOpen(true)} />
    </>
  );
};

export default Blogs;
