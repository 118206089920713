import React, { useEffect } from "react";
import BlogList from "./BlogList";
import { useFirestore } from "../../../context/FirestoreContext";

const BlogProp = ({ column, StyleVarProp }) => {
  const { blogs } = useFirestore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {blogs.map((item) => (
            <div key={item.id} className={column}>
              <BlogList StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogProp;
