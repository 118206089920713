import React, { useState } from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaSpinner } from "react-icons/fa";
import { useFirestore } from "../../context/FirestoreContext";

const teamData1 = [
  {
    image: "team-01",
    name: "MD.Shahzad Ali",
    designation: "Director",
    location: "India",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "team-02",
    name: "Neetu Sharma",
    designation: "Co-founder",
    location: "India",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
  {
    image: "team-03",
    name: "Seema Sharma",
    designation: "Senior Designer",
    location: "India",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
];

const teamData2 = [
  {
    image: "team-04",
    name: "Md.imran",
    designation: "Web Developer",
    location: "India",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
  {
    image: "team-05",
    name: "Murliraj Iyer",
    designation: "Graphic Designer",
    location: "India",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
];

const TeamOne = ({ column, teamStyle }) => {
  const [itemData, setItemData] = useState([...teamData1]);
  const [noMorePost, setNoMorePost] = useState(false);

  const handleLoadmore = () => {
    setItemData([...itemData, ...teamData2]);
    setNoMorePost(true);
    window.scrollBy(0, window.innerHeight);
  };
  return (
    <div className="row row--15">
      {itemData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/team/${data.image}.jpg`}
                    alt="CodeHunk Technology team"
                  />
                </figure>
                <figcaption className="content">
                  <h2 className="title">{data.name}</h2>
                  <h6 className="subtitle theme-gradient">
                    {data.designation}
                  </h6>
                  <span className="team-form">
                    <img
                      src="./images/team/location.svg"
                      alt="CodeHunk Technology location"
                    />
                    <span className="location">{data.location}</span>
                  </span>
                  <p className="description">{data.description}</p>
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}

      {!noMorePost && (
        <button
          style={{
            display: "flex",
            float: "right",
            margin: "2rem 2rem 1rem auto",
          }}
          className="btn btn-default btn-large btn-icon "
          onClick={handleLoadmore}
          disabled={noMorePost}
        >
          {noMorePost ? "No Item Here" : <span>View All</span>}
        </button>
      )}
    </div>
  );
};

export default TeamOne;
