import React, { useEffect, useState } from "react";
import Layout from "../common/Layout";
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import { useFirestore } from "../context/FirestoreContext";
import { useParams } from "react-router-dom";

const ProjectDetailsPage = () => {
  const { fetchPortfolioByID } = useFirestore();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const res = await fetchPortfolioByID(id);
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return <Layout>{data && <PortfolioDetailsContent data={data} />}</Layout>;
};

export default ProjectDetailsPage;
