import React from "react";
import { Adsense } from "@ctrl/react-adsense";
import "./AdsenseStyle.css";

function AdsenseExample() {
  return (
    <>
      <Adsense
        className="ExampleAdSlot"
        style={{ display: 'block' }}
        client="ca-pub-4976810873502759"
        slot="4527575667"
        format="auto"
        responsive="true"
      />
    </>
  );
}

export default AdsenseExample;
