import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import TestimonialThree from "./TestimonialThree";

const Testimonial = () => {
  return (
    <div id="review">
      <div className="rwt-testimonial-area rn-section-gap">
        <div className="container">
          <div className="row mb--20">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Client Feedback"
                title="Our Clients Feedback"
                description="We provide web and mobile development for <br /> startups and company business."
              />
            </div>
          </div>
          <TestimonialThree teamStyle="" />
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
