import React, { useState } from "react";
import HeaderOne from "./header/HeaderOne";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";
import FooterOne from "./footer/FooterOne";

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        {/* <FooterTwo />
        <Copyright /> */}
        <FooterOne requestMeeting={() => setOpen(true)} />
      </main>
    </>
  );
};
export default Layout;
