import "./App.css";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error from "./pages/Error";
import HomeDefault from "./pages/HomeDefault";
import "./assets/scss/style.scss";
import HeaderOne from "./common/header/HeaderOne";
import Blogs from "./pages/Blogs";
import AllProjectsPage from "./pages/AllProjectsPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage.js";
import { FirestoreProvider } from "./context/FirestoreContext.js";
import BlogDetails from "./pages/BlogDetails.js";

function App() {
  return (
    <FirestoreProvider>
      <BrowserRouter>
        <PageScrollTop>
          <HeaderOne
            btnStyle="btn-small round btn-icon"
            HeaderSTyle="header-transparent"
          />
          <Routes>
            <Route exact path="/" element={<HomeDefault />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route path={"/blog-details/:id"} element={<BlogDetails />} />
            <Route exact path="/all-projects" element={<AllProjectsPage />} />
            <Route
              path="/project-details/:id"
              element={<ProjectDetailsPage />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </PageScrollTop>
      </BrowserRouter>
    </FirestoreProvider>
  );
}

export default App;
