import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import BrandThree from "./BrandThree";

const Brand = () => {
  return (
    <>
      <div className="rwt-brand-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Our Awesome Client"
                title="Companies we worked with"
                description=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt--10">
              <BrandThree brandStyle="brand-style-2" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Brand;
