// src/contexts/FirestoreContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { COLLECTIONS, db } from "../firebase";

const FirestoreContext = createContext();

export const useFirestore = () => {
  return useContext(FirestoreContext);
};

export const FirestoreProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  // const [testimonials, setTestimonials] = useState([]);
  // const [ourExperts, setOurExperts] = useState([]);

  const fetchBlogs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, COLLECTIONS.blogs));

      const blogsArr = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setBlogs(blogsArr);
    } catch (error) {
      console.log("failed to fetch blogs", error);
    }
  };

  const fetchBlogByID = async (id) => {
    try {
      const docRef = doc(db, COLLECTIONS.blogs, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      console.log("failed to fetch blogs", error);
      return null;
    }
  };

  const fetchPortfolio = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, COLLECTIONS.portfolio)
      );

      const portfolioArr = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setPortfolio(portfolioArr);
    } catch (error) {
      console.log("failed to fetch blogs", error);
    }
  };

  const fetchPortfolioByID = async (id) => {
    try {
      const docRef = doc(db, COLLECTIONS.portfolio, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      console.log("failed to fetch blogs", error);
      return null;
    }
  };

  // const fetchTestimonials = async () => {
  //   try {
  //     const querySnapshot = await getDocs(
  //       collection(db, COLLECTIONS.testimonials)
  //     );

  //     const testimonialsArr = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));

  //     setTestimonials(testimonialsArr);
  //   } catch (error) {
  //     console.log("failed to fetch blogs", error);
  //   }
  // };

  // const fetchOurExperts = async () => {
  //   try {
  //     const querySnapshot = await getDocs(
  //       collection(db, COLLECTIONS.ourExperts)
  //     );

  //     const ourExpertsArr = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));

  //     setOurExperts(ourExpertsArr);
  //   } catch (error) {
  //     console.log("failed to fetch blogs", error);
  //   }
  // };

  useEffect(() => {
    fetchPortfolio();
    fetchBlogs();
  }, []);

  return (
    <FirestoreContext.Provider
      value={{ blogs, portfolio, fetchBlogByID, fetchPortfolioByID }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};
