import React from "react";
import { FiCode, FiTablet, FiTv } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import { FaPaintBrush, FaQuestion, FaWikipediaW } from "react-icons/fa";

const ServiceList = [
  {
    icon: <FiCode />,
    title: "Hybrid Application",
    description:
      "Being one of the best Mobile App Development Companies in the industry, we know what it takes to convert your vision into reality.",
  },
  {
    icon: <FiTablet />,
    title: "Mobile Application",
    description:
      "To build a successful mobile app, it is crucial to ensure that it never crashes. A lot goes into developing solutions that win hearts.",
  },
  {
    icon: <FiTv />,
    title: "Backend Services",
    description:
      "Backend Services Businesses must have the greatest website possible. Backend is essential for this goal because it enables businesses to automate processes.",
  },
  {
    icon: <FaWikipediaW />,
    title: "Web Services",
    description:
      "Responsive Website Design With the increasing trends, we offer a full stack of creative design services to cater to your business needs.",
  },
  {
    icon: <FaPaintBrush />,
    title: "UI/UX Design",
    description:
      " Our app design department is specialized in decoding the user’s wants and needs, making for well-informed UI and UX decisions.",
  },
  {
    icon: <FaQuestion />,
    title: "QA & Testing",
    description:
      " We’ll make sure that your mobile app is in top shape for its app store launch and that it will make good on its promise.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4
                  className="title w-600"
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
