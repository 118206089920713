import React, { useState, useEffect } from "react";
import PortfolioItem from "./PortfolioItem";
import { Link } from "react-router-dom";
import { useFirestore } from "../../context/FirestoreContext";

const PortfolioOne = ({ Column }) => {
  const { portfolio } = useFirestore();
  const [dataVisibleCount] = useState(6);
  const [noMorePost, setNoMorePost] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setVisibleItems(portfolio.filter((item) => item.id <= dataVisibleCount));
  }, [portfolio]);

  return (
    <>
      <div className="row row--15">
        {visibleItems.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioItem portfolio={item} />
          </div>
        ))}
      </div>

      <div className="row row--15">
        <div className="col-lg-12">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="rwt-load-more text-center mt--50"
          >
            {!noMorePost && (
              <Link to={"/all-projects"}>
                <button
                  className="btn btn-default btn-large btn-icon "
                  disabled={noMorePost ? "disabled" : null}
                >
                  <span style={{ width: "fit-content" }}>View all</span>
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioOne;
