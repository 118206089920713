// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBE5His_MbpkD3DKWnOnXQVmXL32LwT5eM",
  authDomain: "codehunk-cae4c.firebaseapp.com",
  databaseURL: "https://codehunk-cae4c-default-rtdb.firebaseio.com",
  projectId: "codehunk-cae4c",
  storageBucket: "codehunk-cae4c.appspot.com",
  messagingSenderId: "53340210263",
  appId: "1:53340210263:web:7c2f20296e37ef007d8f12",
  measurementId: "G-2GW49Z7ZV0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
let env = "production";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  env = "sandbox";
}

const COLLECTIONS = {
  blogs: `${env}/${env}/blogs`,
  portfolio: `${env}/${env}/portfolio`,
  // testimonials: `${env}/${env}/testimonials`,
  // ourExperts: `${env}/${env}/our-experts`,
};

export { db, COLLECTIONS };
