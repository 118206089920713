import React from "react";
import Typed from "react-typed";
import Lottie from "react-lottie";
import animationData from "../about/gif.json";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const AboutOne = () => {
  return (
    <>
      <div id="about">
        <div className="rwt-about-area rn-section-gap">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Lottie options={lottieOptions} />
                </div>
              </div>

              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="content">
                  <div className="section-title">
                    <h2 className="title">
                      About Us <br />{" "}
                      <Typed
                        className="theme-gradient"
                        strings={["Consulting.", "Development.", "Agency."]}
                        typeSpeed={80}
                        backSpeed={5}
                        backDelay={1000}
                        loop
                      />
                    </h2>
                    <p>
                      Welcome to CodeHunk Technology, where technology meets
                      innovation and solutions are reimagined. We are a dynamic
                      and forward-thinking IT company dedicated to providing
                      cutting-edge technology services and solutions to
                      businesses of all sizes. With a passion for excellence and
                      a team of highly skilled professionals, we strive to
                      empower our clients with the tools and expertise they need
                      to thrive in the digital landscape.
                    </p>
                    <p>
                      CUSTOMIZED AND INTERACTIVE MOBILE & WEB SOLUTIONS At
                      CodeHunk Technology, we are focused on enhancing the value
                      of your business We offer solutions that give you a
                      competitive edge in the online market and a stronger brand
                      image.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutOne;
