import React from "react";
import PortfolioItem from "../elements/portfolio/PortfolioItem";

import Layout from "../common/Layout";
import { useFirestore } from "../context/FirestoreContext";

const AllProjectsPage = () => {
  return (
    <Layout>
      <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
    </Layout>
  );
};

const PortfolioOne = ({ Column }) => {
  const { portfolio } = useFirestore();

  return (
    <>
      <div style={{ margin: "3rem 4rem 9rem 4rem" }} className="row row--15">
        {portfolio?.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioItem portfolio={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default AllProjectsPage;
