import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import TeamOne from "./TeamOne";

const Team = () => {
  return (
    <div id="team">
      {/* Start Elements Area  */}
      <div className="rwt-team-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Our Experts"
                title="Our Expert Team"
                description="Our team is comprised of industry experts who are passionate about their work, and have an extensive background in a wide variety of applications."
              />
            </div>
          </div>
          <TeamOne
            column="col-lg-4 col-md-6 col-12 mt--30"
            teamStyle="team-style-default"
          />
        </div>
      </div>
      {/* End Elements Area  */}
    </div>
  );
};
export default Team;
