import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
import AboutOne from "../elements/about/AboutOne";
import Mission from "../elements/about/Mission";
import DigitalAgency from "./DigitalAgency";
import Service from "../elements/service/Service";
import PersonalPortfolio from "./PersonalPortfolio";
import Testimonial from "../elements/testimonial/Testimonial";
import Progressbar from "../elements/progressbar/Progressbar";
import Team from "../elements/team/Team";
import Brand from "../elements/brand/Brand";
import Calendly from "../common/footer/Calendly";
import { Modal } from "antd";
import BlogGridView from "../components/blog/BlogGridView";

const data2 = {
  title: "Frequently Asked Questions",
  isVisible: false,
  description:
    " We envision ourselves to be anointed as a trusted partner of companies whilst adding organic value to their business in a fruitful manner.Certainly! We'd be happy to showcase our portfolio of successful projects. ",
  description2:
    "Contact us, and we'll share relevant case studies and examples that align with your specific interests and requirements.If you have any more questions or need further information, feel free to get in touch with us. We're here to assist you every step of the way!",
  strings: [],
  getItems: (panelStyle) => [
    {
      key: "1",
      label: "What services does CodeHunk Technology provide?",
      children: (
        <p>
          {" "}
          At CodeHunk Technology, we offer a wide range of IT services to cater
          to diverse business needs. Our services include: - Mobile and Web
          Application Development - Custom Software Solutions - E-commerce
          Development - Search Engine Optimization (SEO) - Artificial
          Intelligence and Machine Learning - Cloud Solutions and Services -
          Digital Marketing and Branding - IT Consulting and Support
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "How experienced is your team?",
      children: (
        <p>
          Our team consists of highly skilled professionals with years of
          experience in their respective domains. From developers and designers
          to digital marketers and AI experts, our team brings together a wealth
          of knowledge and expertise. We continuously invest in our team's
          professional development, ensuring they stay updated with the latest
          technologies and industry trends.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: "Can you handle projects of different sizes?",
      children: (
        <p>
          {" "}
          Absolutely! We are equipped to handle projects of varying sizes, from
          small startups to large enterprises. Our team's flexibility and
          scalability allow us to adapt our services to meet the specific
          requirements of each project, ensuring top-notch results, regardless
          of the project's scope.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label:
        "How do you ensure the security of sensitive data in software development?",
      children: (
        <p>
          {" "}
          At CodeHunk Technology, we take data security seriously. We follow
          industry best practices and implement robust security measures
          throughout the software development process. Our team adheres to
          secure coding standards, regularly performs security audits, and uses
          encryption techniques to protect sensitive data..
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: "How can I get started with CodeHunk Technology?",
      children: (
        <p>
          {" "}
          Getting started is easy! Simply reach out to us through our website or
          contact details, and one of our representatives will be in touch
          promptly. We'll discuss your project requirements, provide insights,
          and outline a customized plan to turn your vision into reality..
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: "What sets your web and mobile app development apart from others?",
      children: (
        <p>
          {" "}
          Our web and mobile app development services stand out due to our focus
          on user-centric design, seamless functionality, and high performance.
          We prioritize understanding your target audience, ensuring intuitive
          user experiences, and delivering fast-loading and responsive
          applications that leave a lasting impression..
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: "Can you help improve our website's search engine ranking?",
      children: (
        <p>
          {" "}
          Yes, we offer comprehensive SEO services aimed at improving your
          website's search engine ranking. Our team conducts in-depth keyword
          research, optimizes on-page elements, builds high-quality backlinks,
          and provides regular performance reports to help you achieve better
          visibility and organic traffic.
        </p>
      ),
      style: panelStyle,
    },
  ],
};
const data = {
  title: "Why Choose US",
  isVisible: false,
  description:
    "At CodeHunk Technology, we offer a comprehensive range of IT services tailored to meet the diverse needs of our clients, including but not limited to:",
  description2:
    "Mobile and Web Application Development, Custom Software Solutions, E-commerce Development, Search Engine Optimization (SEO)Artificial Intelligence and Machine Learning Cloud Solutions and Services Digital Marketing and Branding IT Consulting and Support. Join us on this exciting journey of innovation and transformation. Let's explore the endless possibilities technology has to offer and unlock your business's true potential together. Contact us today, and let's embark on a path to a digitally empowered future!",
  strings: [
    "Our Expert Team.",
    "Ready for Challenges.",
    "Bring your Ideas to Life.",
  ],
  getItems: (panelStyle) => [
    {
      key: "1",
      label: "Our Vision",
      children: (
        <p>
          {" "}
          At CodeHunk Technology, our vision is to be a driving force in the
          digital transformation of businesses across industries. We envision a
          world where technology serves as an enabler, revolutionizing the way
          companies operate and accelerating their growth and success. By
          staying at the forefront of emerging technologies, we aim to be the
          go-to partner for businesses seeking innovative IT solutions.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "Our Mission",
      children: (
        <p>
          Our mission is simple yet powerful – to deliver innovative, reliable,
          and scalable IT solutions that empower our clients to achieve their
          goals and stay ahead of the competition. We are committed to building
          strong, long-lasting partnerships with our clients, understanding
          their unique needs, and providing tailored solutions that align with
          their objectives. We strive to deliver exceptional value and tangible
          results, fostering growth and success for all stakeholders involved.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: "Expert Team",
      children: (
        <p>
          {" "}
          Our team consists of talented and experienced professionals who are
          passionate about technology and dedicated to delivering excellence in
          every project we undertake. From skilled developers and designers to
          savvy digital marketers and AI experts, we bring together diverse
          talents to create holistic solutions.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: "Innovation Focus",
      children: (
        <p>
          {" "}
          Innovation is at the core of everything we do. We continuously explore
          and embrace emerging technologies, keeping ourselves updated with the
          latest trends to provide our clients with the most relevant and
          forward-looking solutions.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: "Client-Centric Approach",
      children: (
        <p>
          {" "}
          We believe that our success is tied to the success of our clients.
          Hence, we adopt a client-centric approach, actively listening to our
          clients, understanding their needs, and working collaboratively to
          find the best solutions that address their challenges and
          opportunities.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: "Integrity and Transparency",
      children: (
        <p>
          {" "}
          Honesty, integrity, and transparency are the pillars of our business.
          We prioritize open communication, ethical practices, and fair dealings
          in all our interactions, fostering trust and long-term relationships
          with our clients and partners.
        </p>
      ),
      style: panelStyle,
    },
  ],
};

const HomeDefault = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* <SEO title="CodeHunk Technology" /> */}
      <main className="page-wrapper">
        <DigitalAgency />
        <Separator />
        <AboutOne />
        <Separator />
        <Service />
        <Separator />
        <Mission data={data} requestMeeting={() => setOpen(true)} />
        <Separator />
        <PersonalPortfolio />
        <Separator />
        <Testimonial />
        {/* <Separator /> */}
        {/* <Progressbar /> */}
        <Separator />
        <Team />
        <Separator />
        <Mission data={data2} requestMeeting={() => setOpen(true)} />
        <Separator />
        {/* <BlogGridView /> */}
        <Separator />
        <Brand />
        <Separator />
        <FooterOne requestMeeting={() => setOpen(true)} />
        <Separator />
        <Copyright />
        <Modal
          className="modal1"
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={"80%"}
        >
          <Calendly />
        </Modal>
      </main>
    </>
  );
};
export default HomeDefault;
