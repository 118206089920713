import React from "react";
import { Link } from "react-router-dom";

const Nav = ({ onClose }) => {
  return (
    <ul className="mainmenu">
      <li onClick={onClose} className="has-droupdown">
        <Link to="/">Home</Link>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#about">About Us</a>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#services">Service</a>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#portfolio">Portfolio</a>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#review">Review</a>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#team">Team</a>
      </li>
      <li onClick={onClose} className="has-droupdown">
        <a href="/#blogs">Blogs</a>
      </li>

      <li onClick={onClose} className="has-droupdown">
        <a href="/#contact">Contact Us</a>
      </li>
    </ul>
  );
};
export default Nav;
