import React from "react";
import BlogProp2 from "./itemProp/BlogProps2";
import HeaderOne from "../../common/header/HeaderOne";

const BlogGridViewPage = () => {
  const style1 = {
    textAlign: "center",
    marginTop: "7rem",
    marginBottom: "-5rem",
  };
  return (
    <div id="blogs">
      <>
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        <h1 style={style1} className="blogs-heading theme-gradient">
          Our Latest News
        </h1>
        <div className="main-content">
          {/* Start Blog Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--20">
                <BlogProp2
                  column="col-lg-4 col-md-6 col-12 mt--30"
                  StyleVarProp="box-card-style-default"
                />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>
      </>
    </div>
  );
};
export default BlogGridViewPage;
