import React from "react";
import footerOne from "../../data/footer/footerOne.json";
import FacebookMessenger from "./FacebookMessenger";
import ScrollTop from "./ScrollTop";
import "./FooterSample.css";
import ReactWhatsapp from "react-whatsapp";
import { FiLinkedin, FiMail, FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const style = {};
const FooterOne = ({ requestMeeting }) => {
  return (
    <div id="contact">
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="row"
            >
              <h1 className="text-center">Contact Us</h1>
              <p
                className="text-center"
                style={{
                  display: "block",
                  textAlign: "center",
                  width: "fit-content",
                }}
              >
                <span
                  className="text-center"
                  style={{
                    width: "60%",
                    display: "block",
                    textAlign: "center",
                    width: "fit-content",
                  }}
                >
                  {" "}
                  Learn how CodeHunk Technology's product design and app
                  development services can help you build better mobile
                  relationships with your customers. Let's Talk About Your
                  Project!.
                </span>
              </p>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="">
                  <h4 className="title text-center">
                    {" "}
                    <button
                      onClick={requestMeeting}
                      className="btn btn-default btn-large btn-icon "
                    >
                      <span style={{ width: "fit-content" }}>
                        Request a meeting
                      </span>
                    </button>
                  </h4>
                  <div className="inner">
                    <h6 className="subtitle text-center">
                      1000+ Our clients are subscribe Around the World
                    </h6>
                    <div
                      id="contactIcons"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <a
                          href="tel:+918986733411"
                          className="ui-link"
                          target="_blank"
                        >
                          <FiPhoneCall
                            style={{ fontSize: "3rem", color: "white" }}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="mailto:hr.codehunktech@gmail.com"
                          target="_blank"
                        >
                          <FiMail
                            style={{ fontSize: "3rem", color: "white" }}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.linkedin.com/company/codehunk-technology/"
                          target="_blank"
                        >
                          <FiLinkedin
                            style={{ fontSize: "3rem", color: "white" }}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.upwork.com/agencies/~01dc7169a5e90a4da3/"
                          target="_blank"
                        >
                          <img
                            src="/images/logo/upwork.png"
                            alt="Upwork Profile"
                            style={{
                              width: "3.5rem",
                              color: "white",
                            }}
                          />
                        </a>
                      </div>
                      <div>
                        <Whatsapp />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </footer>
      {/* <FacebookMessenger /> */}
    </div>
  );
};

function Whatsapp() {
  return (
    <ReactWhatsapp
      style={{
        background: "transparent",
        border: "none",
        margin: "0",
        width: "3rem",
      }}
      number="+91 8986733411"
      message="Write your message here for us..."
    >
      <FaWhatsapp
        id="whatsappIcon"
        style={{ fontSize: "3rem", color: "white", marginLeft: "-7" }}
      />
    </ReactWhatsapp>
  );
}

export default FooterOne;
