import React, { useEffect, useState } from "react";
import BlogDetailsContent from "../components/blog/BlogDetailsContent";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { useParams } from "react-router-dom";
import { useFirestore } from "../context/FirestoreContext";

const BlogDetails = ({}) => {
  const { fetchBlogByID } = useFirestore();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const res = await fetchBlogByID(id);
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {/* <SEO title="Blog Details" /> */}
      <Layout>
        <div className="rn-blog-details-area">
          {data && <BlogDetailsContent data={data} />}
        </div>
      </Layout>
    </>
  );
};

export default BlogDetails;
