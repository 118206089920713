import React from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";

const Service = () => {
  return (
    <div id="services">
      <div className="rn-service-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="What we can do for you"
                title="Services provide for you"
              />
            </div>
          </div>
          <ServiceOne
            serviceStyle="service__style--1 icon-circle-style"
            textAlign="text-center"
          />
        </div>
      </div>
    </div>
  );
};

export default Service;
