import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";

const mission = ({
  data: { title, isVisible, description, strings, getItems, description2 },
  requestMeeting,
}) => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                {title} <br />{" "}
                {strings.length !== 0 && (
                  <Typed
                    className="theme-gradient"
                    strings={strings}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                )}
              </h2>
              <p>{description}</p>

              <p>{description2}</p>

              <h4 className="title text-center">
                {" "}
                <button
                  onClick={requestMeeting}
                  className="btn btn-default btn-large btn-icon "
                >
                  <span style={{ width: "fit-content" }}>
                    Request a meeting
                  </span>
                </button>
              </h4>

              {isVisible && (
                <div className="read-more-btn mt--50">
                  <Link className="btn-default btn-icon" to="#">
                    Learn More{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne getItems={getItems} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
