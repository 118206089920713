import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = () => {
  const url = "https://calendly.com/codehunktechnology/30min";
  return (
    <div className="App">
      <InlineWidget
        styles={{
          height: "70vh",
          padding: "30px 0",
          margin: "30px 0",
          overflowY: "hidden",
          boxSizing: "content-box",
        }}
        url={url}
      />
    </div>
  );
};

export default Calendly;
